<template>
  <div id="vehicle-ctrl" class="vehicle-ctrl" style="height: 100%">
    <vxe-modal
      v-model="ctrlVisible"
      ref="vxeModal"
      :width="modalWidth"
      height="100%"
      :class="[!isCanSendWebsocketCmd ? 'remote-control-warn' : '', 'ctrl-bg']"
      :title="modalTitle"
      @close="handleCloaseCtrl"
      @hide="handleCloaseCtrl"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :esc-closable="true"
      :position="position"
      transfer
    >
      <!-- 自定义Modal  Header -->
      <template #header>
        <div class="vxe-modal--header-title">{{ modalTitle }}</div>
        <div class="vxe-modal--header-right">
          <a-button style="margin-right: 10px" @click="toRtcScreen" v-hasPermi="['monitor_remote_screen_button']">
            切换投屏
          </a-button>
          <a-icon
            style="margin-right: 5px"
            type="line-chart"
            @click="handleOpenChart"
            v-hasPermi="['tenant:iot:remotecontrol:delay']"
          />
          <a-button v-if="initIsRtc" @click="changeAdminVideoType" v-hasPermi="['remote_vedio_type_switch']">
            {{ signAdminVideoType === 'rtc' ? '切换rtmp' : '切换rtc' }}
          </a-button>
          <i
            v-if="isTianzun"
            @click="connectVoice(voiceOn ? 'off' : 'on')"
            :title="voiceOn ? '语音开启中' : '语音关闭中'"
            class="vxe-modal--right-btn"
            :class="voiceOn ? 'vxe-icon-voice-fill' : 'vxe-icon-voice'"
          ></i>
          <i
            title="vxe.modal.zoomIn"
            @click="vxeZoom"
            :class="zoomIn ? 'vxe-icon-maximize' : 'vxe-icon-square'"
            class="vxe-modal--zoom-btn trigger--btn"
          ></i>
          <i title="vxe.modal.close" @click="vxeClick" class="vxe-modal--close-btn trigger--btn vxe-icon-close"></i>
        </div>
      </template>
      <div class="ctrl-container">
        <div class="ctrl-left">
          <div class="side-camera" :class="[leftCamera.url || leftCamera.info ? '' : 'no-video-small',videoType === 'brtc' ? 'b-rtc' :'']">
            <!-- 左摄像头 -->
            <div id="therevideo" v-if="videoType === 'brtc'">
            </div>
            <revo-video-player
              @handleReady="handleReady"
              @handleError="handleError"
              :vehicleId="vehicleId"
              :vehicleCameraDeviceInfo="leftCamera.info"
              :url="leftCamera.url"
              :videoType="isAdmin ? signAdminVideoType : videoType"
              :isLive="true"
              v-else-if="(leftCamera.url || leftCamera.info) && reLoadRevo"
            />
          </div>
          <!-- 遥控实时信息 -->
          <div class="ctrl-info">
            <div class="speed">
              <img src="../../../../../../assets/images/control/bg-speed.png" height="100%" />
              <div class="speed-over">
                <span class="speed-text speed-media">{{ runningSpeed }}</span>
                <span class="speed-label speed-label-media">速度(KM/H)</span>
              </div>
            </div>
            <div class="rotate">
              <img src="../../../../../../assets/images/control/bg-direction.png" height="100%" />
              <div class="rotate-over">
                <img
                  class="rotate-arrow"
                  :style="{ transform: 'rotate(' + locationHeading + 'deg)' }"
                  src="../../../../../../assets/images/control/ico-arrow.png"
                />
                <span class="rotate-text rotate-media">{{ locationHeading }}°</span>
                <span class="rotate-label rotate-label-media">方向{{ locationHeadingLRStr }}</span>
              </div>
            </div>
            <div class="info-content">
              <div class="soc" :style="leftLabelStyle">
                <span class="info-item">电量：{{ currentSoc }}%</span>
                <img
                  :style="{ width: currentSoc + '%', height: '14px', marginTop: '4px' }"
                  src="../../../../../../assets/images/control/bg-remainingPower.png"
                />
              </div>
              <div class="brake" :style="leftLabelStyle">
                <span class="info-item">刹车情况：{{ cmd.brake ? '刹车中' : '未刹车' }}</span>
              </div>
              <div class="state" :style="leftLabelStyle">
                <span class="info-item">作业状态： {{ workStatus }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="ctrl-center">
          <div class="main-camera" :class="[mainCamera.url || mainCamera.info ? '' : 'no-video-big',videoType === 'brtc' ? 'b-rtc' :'']">
            <div id="therevideo2" v-if="videoType === 'brtc'">
            </div>
            <revo-video-player
              @handleReady="handleReady"
              @handleError="handleError"
              :is-live="true"
              :vehicleId="vehicleId"
              :rtcInfo="rtcInfo"
              :vehicleCameraDeviceInfo="mainCamera.info"
              :direction="mainCamera.direction"
              :url="mainCamera.url"
              :videoType="isAdmin ? signAdminVideoType : videoType"
              v-else-if="(mainCamera.url || mainCamera.info) && reLoadRevo"
            />
          </div>
          <!-- Control Tabs -->
          <div class="control-header">
            <div class="control-tabs">
              <div
                class="tab-item"
                :class="{ active: activeTab === 'vehicle' }"
                @click="activeTab = 'vehicle'"
              >
                车辆遥控
              </div>
              <div
                class="tab-item"
                :class="{ active: activeTab === 'device' }"
                @click="()=> {activeTab = 'device';} "
              >
                设备控制
              </div>
            </div>
          </div>

          <div class="ctrl-btn" :style="activeTab === 'device' ? 'justify-content: flex-start;align-items: flex-start' : ''">
            <!-- Vehicle Control -->
            <div class="btn-list" v-if="activeTab === 'vehicle'">
              <div class="row-1">
                <div class="col-1">
                  <!-- <img @mousedown="turnLeftClickDown" @mouseup="turnLeftClickUp" class="ctrl-btn-left" src="../../../../../../assets/images/control/btn-a.png" /> -->
                  <img
                    @mousedown="turnLeftClickDown"
                    @mouseup="turnLeftClickUp"
                    class="ctrl-btn-left"
                    :src="getImgSrc('btn-a', leftBtn)"
                  />
                  <div class="btn-v">
                    <img @click="forward" class="ctrl-w" :src="getImgSrc('btn-w', upGearBtn)" />
                    <img @click="reversing" class="ctrl-s" :src="getImgSrc('btn-s', downGearBtn)" />
                  </div>
                  <img
                    @mousedown="turnRightClickDown"
                    @mouseup="turnRightClickUp"
                    class="ctrl-btn-right"
                    :src="getImgSrc('btn-d', rightBtn)"
                  />
                </div>
                <div class="col-2">
                  <div class="btn-v">
                    <img @click="neutral" class="ctrl-n" :src="getImgSrc('btn-n', neutralPositionBtn)" />
                    <img @click="parking" class="ctrl-p" :src="getImgSrc('btn-p', parkingBtn)" />
                  </div>
                  <div class="col-2-right">
                    <div class="ctrl-mode">
                      <div>
                        <img @click="unautomode" class="ctrl-n" :src="manualModeImgSrc" />
                        <img @click="automode" class="ctrl-n" :src="autoModeImgSrc" />
                      </div>
                      <span :class="[!isLoginSuccess ? 'ctrl-mode-text-grey' : 'ctrl-mode-text']">驾驶模式</span>
                    </div>

                    <div class="ctrl-jk">
                      <img @mousedown="guanDown" @mouseup="guanUp" :src="getImgSrc('btn-j', throttleBtn)" />
                      <img @mousedown="brakeDown" @mouseup="brakeUp" :src="getImgSrc('btn-k', brakeBtn)" />
                    </div>
                  </div>
                </div>
              </div>
              <img @click="estop" class="ctrl-estop" :src="getImgSrc('btn-space', emergencyStop)" />
            </div>

            <!-- Device Control -->
            <div class="device-control" v-if="activeTab === 'device'">
              <div style="display: flex;gap: 50px;align-items: center;">
                <div class="device-buttons" style="margin: 10px 0 10px; display: table;">
                  <button
                    class="device-btn"
                    :class="{ active: activeDevice === 'fogCannon' }"
                    @click="selectDevice('fogCannon')"
                  >
                    雾炮
                  </button>
                  <button
                    class="device-btn"
                    :class="{ active: activeDevice === 'sprayArm' }"
                    @click="selectDevice('sprayArm')"
                  >
                    喷杆
                  </button>
                  <button
                    class="device-btn"
                    :class="{ active: activeDevice === 'nozzle' }"
                    @click="selectDevice('nozzle')"
                  >
                    喷嘴
                  </button>
                </div>
                <div class="device-buttons" style="margin: 10px 0 10px;">
                  <button
                    class="device-btn"
                    :class="{ active: switchConfig[activeDevice] }"
                    @click="switchChange(true)"
                  >
                    开
                  </button>
                  <button
                    class="device-btn"
                    :class="{ active: !switchConfig[activeDevice] }"
                    @click="switchChange(false)"
                  >
                    关
                  </button>
                </div>
              </div>
              <div class="device-controls">
                <div class="row-1">
                  <div class="col-1">
                    <!-- <img @mousedown="turnLeftClickDown" @mouseup="turnLeftClickUp" class="ctrl-btn-left" src="../../../../../../assets/images/control/btn-a.png" /> -->
                    <img
                      @mousedown="turnLeftClickDownofDevice"
                      @mouseup="turnLeftClickUpofDevice"
                      class="ctrl-btn-left"
                      :src="getImgSrc('btn-a', leftBtn)"
                    />
                    <div class="btn-v">
                      <img @click="forwardofDevice" class="ctrl-w" :src="getImgSrc('btn-w', upGearBtn)" />
                      <img @click="reversingofDevice" class="ctrl-s" :src="getImgSrc('btn-s', downGearBtn)" />
                    </div>
                    <img
                      @mousedown="turnRightClickDownofDevice"
                      @mouseup="turnRightClickUpofDevice"
                      class="ctrl-btn-right"
                      :src="getImgSrc('btn-d', rightBtn)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ctrl-right">
          <div class="side-camera" :class="[rightCamera.url || rightCamera.info ? '' : 'no-video-small',videoType === 'brtc' ? 'b-rtc' :'']">
            <div id="therevideo3" v-if="videoType === 'brtc'">
            </div>
            <revo-video-player
              @handleReady="handleReady"
              @handleError="handleError"
              :vehicleId="vehicleId"
              :vehicleCameraDeviceInfo="rightCamera.info"
              :direction="rightCamera.direction"
              :url="rightCamera.url"
              :videoType="isAdmin ? signAdminVideoType : videoType"
              :isLive="true"
              v-else-if="(rightCamera.url || rightCamera.info) && reLoadRevo"
            />
          </div>
          <div class="side-camera" :class="[backCamera.url || backCamera.info ? '' : 'no-video-small',videoType === 'brtc' ? 'b-rtc' :'']">
            <div id="therevideo4" v-if="videoType === 'brtc'">
            </div>
            <revo-video-player
              @handleReady="handleReady"
              @handleError="handleError"
              :vehicleId="vehicleId"
              :vehicleCameraDeviceInfo="backCamera.info"
              :direction="backCamera.direction"
              :url="backCamera.url"
              :videoType="isAdmin ? signAdminVideoType : videoType"
              :isLive="true"
              v-else-if="(backCamera.url || backCamera.info) && reLoadRevo"
            />
          </div>
          <div class="ctrl-map">
            <map-page ref="mapRef" :params="mapPageParams" />
          </div>
        </div>
      </div>
    </vxe-modal>

    <MonitorVehicleCtrlDelayChart ref="chartRef" />
  </div>
</template>

<script>
import get from 'lodash/get'
// import { handleModeData } from '@/translatedata/systemData.js'
import { mapState } from 'vuex'
import { setAutomode, login, logout } from '@/api/device/carcontrol'
import RevoVideoPlayer from './RevoVideoPlayer'
import RtcVoice from './RtcVoice'
import api from '@/api/iot/vehicle'
import { rtmpRealTimeVideo, vehicleLogin } from '@/api/iot/cameraDevice'
import MapPage from '../../../../monitorComponents/mappage'
import { formatSpeed } from '@/utils/formatHelper'
import { AddStartRecord, getTimestampAndSeq } from '@/utils/delayTest'
import MonitorVehicleCtrlDelayChart from './MonitorVehicleCtrlDelayChart'
import BRtcHelper from './baiduRtc/BRtcHelper'
const RtcCameraType = {
  FRONT_CAMERA: 'CAMERA_FRONT_LONG',
  LEFT_CAMERA: 'CAMERA_LEFT_FISH',
  RIGHT_CAMERA: 'CAMERA_RIGHT_FISH',
  BACK_CAMERA: 'CAMERA_BACK_WIDE'
}
export default {
  name: 'MonitorVehicleCtrl',
  mixins: [RtcVoice, BRtcHelper],
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    ctrlVisible: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: ''
    },
    // rtmp rtc brtc
    videoType: {
      type: String,
      default: 'rtmp'
    },
    rtcInfo: {
      type: Object,
      default: () => {}
    },
    showLeftModal: {
      type: Boolean,
      default: false
    },
    vehicleLoginInfo: {
      type: Object,
      default: () => {}
    },
    brtc: {
      type: Object,
      default: () => {}
    },
    formScreen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RevoVideoPlayer,
    MapPage,
    MonitorVehicleCtrlDelayChart
  },
  computed: {
    ...mapState({
      websocketReady: (state) => state.websocketCmd.websocketCmdReady,
      ackData: (state) => state.websocket.ackData
    }),
    vehicleId() {
      return get(this.info, 'vehicleId', '')
    },
    modalTitle() {
      if (!this.ackData.login) {
        // 正在与车端车接中
        return this.vehicleId + '-遥控模式' + ' 与车端连接中-遥控功能暂不可用'
      }
      const loginResult = this.ackData.login.result
      const cmdResult = this.ackData.cmd ? this.ackData.cmd.result : 0
      if (loginResult === 1 || cmdResult === 1) {
        return this.vehicleId + '-遥控模式' + ' 与车端重新连接中-遥控功能暂不可用'
      }

      if (loginResult > 2 || cmdResult > 2) {
        const msg = this.selectDictLabel(this.vehicleRemoteCtlResultOptions, loginResult > 2 ? loginResult : cmdResult)
        return this.vehicleId + '-遥控模式' + ' 车端当前不可被遥控，请稍后重新打开遥控功能，错误信息：' + msg
      }
      if (this.isSwitchAutoModeSuccess === false) {
        const msg =
          this.vehicleId + '-遥控模式 ' + (this.autoModeAction ? '车辆正在切换自动模式中' : '车辆正在切换手动模式中')
        return msg
      }
      return this.vehicleId + '-遥控模式'
    },

    // 速度
    runningSpeed() {
      const speed = formatSpeed(Number(get(this.info, 'runningState.speed', 0)))
      return speed ? speed.toFixed(2) : speed
    },
    // 方向
    locationHeading() {
      const headingVal = Number(get(this.info, 'location.heading', 0))
      return headingVal ? headingVal.toFixed(2) : headingVal
    },
    // 方向是左还是右
    locationHeadingLRStr() {
      const headingVal = Number(get(this.info, 'location.heading', 0))
      return headingVal === 0 ? '' : headingVal < 0 ? '(左)' : '(右)'
    },
    // 当前电量
    currentSoc() {
      const soc = Number(get(this.info, 'runningState.soc', 0))
      return soc ? soc.toFixed(2) : soc
    },
    // 车辆转角
    currentSteer() {
      const steer = Number(get(this.info, 'runningState.steer', 0))
      const steerStr = steer ? steer.toFixed(2) : steer
      if (steer > 0) {
        return '右' + steerStr
      } else if (steer < 0) {
        return '左' + steerStr
      } else {
        return steer
      }
    },
    // 作业状态
    workStatus() {
      // console.log('getWorkStatus')
      if (!this.taskStatusOptions) {
        return ''
      }
      const workStatus = this.ackData.businessState ? this.ackData.businessState.state : -1
      return this.selectDictLabel(this.taskStatusOptions, workStatus)
    },
    // 主摄像头
    mainCameraObj() {
      if (!this.info) {
        return null
      }
      return this.info.cameras.find((c) => c.mainCamera === 'yes')
    },
    mainCameraDeviceInfo() {
      console.log('mainCameraDeviceInfo')
      if (!this.info) {
        return {}
      }
      const mainCameraInfo = this.info.cameras.find((c) => c.mainCamera === 'yes')
      if (this.rtcInfo && this.rtcInfo.vehicleCameraDeviceInfos) {
        for (const item of this.rtcInfo.vehicleCameraDeviceInfos) {
          if (item.cameraType) {
            if (mainCameraInfo.cameraAddr.indexOf(item.cameraType) > -1) {
              return item
            }
          } else {
            if (mainCameraInfo.cameraName === item.cameraName) {
              return item
            }
          }
        }
      }
      return {}
    },

    // 手动模式图片
    manualModeImgSrc() {
      console.log('manualModeImgSrc')
      if (this.automodeFlag === false) {
        // 手动模式
        if (!this.isLoginSuccess) {
          return require('@/assets/images/control/btn-driveMode-hand-grey.png')
        } else {
          return require('@/assets/images/control/btn-driveMode-hand.png')
        }
      } else {
        // 自动模式或未知
        if (!this.isLoginSuccess) {
          return require('@/assets/images/control/btn-driveMode-hand2-grey.png')
        } else {
          return require('@/assets/images/control/btn-driveMode-hand2.png')
        }
      }
    },
    // 自动模式图片
    autoModeImgSrc() {
      if (this.automodeFlag) {
        // 自动模式
        if (!this.isLoginSuccess) {
          return require('@/assets/images/control/btn-driveMode-auto-grey.png')
        } else {
          return require('@/assets/images/control/btn-driveMode-auto.png')
        }
      } else {
        // 手动模式或未知
        if (!this.isLoginSuccess) {
          return require('@/assets/images/control/btn-driveMode-auto2-grey.png')
        } else {
          return require('@/assets/images/control/btn-driveMode-auto2.png')
        }
      }
    },
    modalWidth() {
      let isMax = false
      if (this.$refs.vxeModal) {
        isMax = this.$refs.vxeModal.isMaximized()
      }
      return isMax ? this.screenWidth : this.screenWidth - this.position.left - 10
    },
    // 是否可以发送WS命令
    isCanSendWebsocketCmd() {
      // 登录成功同时处于自动模式
      if (!this.isLoginSuccess) {
        console.log('未登录')
      }
      if (!this.automodeFlag) {
        console.log('非自动模式')
      }
      return this.isLoginSuccess && this.automodeFlag
    }
  },
  data() {
    return {
      activeTab: 'vehicle',
      activeDevice: 'fogCannon',
      url: api.videoUrlList[0],
      switchConfig: {
        fogCannon: false,
        sprayArm: false,
        nozzle: false
      },
      // undefined 是未知， true 是自动 ，false是手动
      automodeFlag: false,
      emergencyStop: false,
      throttleBtn: false,
      brakeBtn: false,
      upBtn: false,
      downBtn: false,
      upGearBtn: false,
      downGearBtn: false,
      leftBtn: false,
      rightBtn: false,
      neutralPositionBtn: false,
      parkingBtn: false,
      cmd: {
        // Vehicle control fields
        left: false,
        right: false,
        brake: false,
        throttle: false,
        slambrake: false,
        drive: false,
        neutral: false,
        reverse: false,
        parking: false,
        // Device control fields
        fogcannon_switch: false,
        fogcannon_up: false,
        fogcannon_down: false,
        fogcannon_left: false,
        fogcannon_right: false,
        sprayrod_switch: false,
        sprayrod_up: false,
        sprayrod_down: false,
        sprayrod_left: false,
        sprayrod_right: false,
        nozzle_switch: false,
        nozzle_up: false,
        nozzle_down: false,
        nozzle_left: false,
        nozzle_right: false,
        timestamp: 0,
        seq: ''
      },
      // 发送车端指令时间
      timer: 200,
      // timer: 3000,
      timerRun: null,
      taskStatusOptions: [],
      // 遥控车辆返回状态结果
      vehicleRemoteCtlResultOptions: [],
      // 左摄像头
      leftCamera: {
        info: null,
        direction: '',
        url: ''
      },
      // 主摄像头（前摄像头）
      mainCamera: {
        info: null,
        direction: '',
        url: ''
      },
      // 右摄像头
      rightCamera: {
        info: null,
        direction: '',
        url: ''
      },
      // 后摄像头
      backCamera: {
        info: null,
        direction: '',
        url: ''
      },
      voiceOn: false,
      zoomIn: false,
      isTianzun: false,
      mapPageParams: {
        // 是否更新地图，当打开其它窗口时，则不更新地图
        unShowDistance: true,
        isUpdateMap: true,
        showHeader: false,
        mapDelayUpdate: true,
        mapDelayUpdateInterval: 1000,
        objectId: 'vehicleId',
        infoWindowTitle: 'vehicleId',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        moduleKey: 'vehicle',
        // 地图数据源
        mapDataSource: 'real',
        // 地图上左侧按钮显示文字
        leftModalTitle: '',
        // 地图上右侧按钮显示文字
        rightModalTitle: '',
        // 车辆显示图标
        objectIcon: { url: require('@/assets/images/map/vehicle-sweeper.png'), size: { width: 80, height: 60 } },
        // 车辆离线图标
        objectIconOff: { url: require('@/assets/images/map/vehicle-off.png'), size: { width: 80, height: 60 } },
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        historyTrackPointsArray: [],
        // 路线规划
        laneDataArray: [],
        lushuConfig: {
          // playIcon: VehiclePlayIcon,
          // 不需要控制车辆方向
          enableRotation: true,
          // 轨迹播放时ICON上方显示的文字
          defaultContent: '',
          // 图标大小
          iconSize: { width: 52, height: 26 },
          // 图标的定位点相对于图标左上角的偏移值
          anchorSize: { width: 27, height: 14 }
        },
        // 是否显示地图轨迹明细
        showMapTrackDetail: false,
        // 当前选中的轨迹对象
        currentTrackObject: null,
        // 正在连接车辆中...
        loadingWebsocket: false,
        loadingWebsocketMsg: '正在连线车辆，请稍后...',
        // 被选中的车移动距离（当前位置距离上一次更新的位置距离）超过多少米后才能移动位置并重置弹窗
        // 但弹窗信息必须实时更新
        selectedObjectMoveDistanceForResetLocation: 2,
        mapPanToOverDistance: 5,
        IsNotShowInfoWindow: true
      },
      screenWidth: document.body.clientWidth,
      // 模式切换成功状态
      isSwitchAutoModeSuccess: undefined,
      // 模式切换动作
      autoModeAction: undefined,
      // 是否登录成功
      isLoginSuccess: false,
      // 登录成功后会设置一次为true
      isCmdSuccess: false,
      signAdminVideoType: this.videoType,
      isAdmin: false,
      reLoadRevo: true,
      initIsRtc: this.rtcInfo.vehicleCameraDeviceInfos
    }
  },
  created() {
    console.log('created', this.videoType)
    //  登陆车辆遥控
    // this.loginVehicle()
    // console.log('created vehicleId:', this.vehicleId)
    this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
      this.vehicleRemoteCtlResultOptions = response.data
    })
    this.initTimer()
  },
  async mounted() {
    if (this.formScreen) {
      await this.initLogin()
    }
    this.handleVehicleLoginInfo(this.vehicleLoginInfo)
    this.handleAckData(this.ackData)
    this.setVehicleAutoMode()
    window.addEventListener('resize', () => {
      this.screenWidth = document.body.clientWidth
      if (!this.$refs.vxeModal) {
        return
      }
      const isMaxSize = this.$refs.vxeModal.isMaximized()
      if (!isMaxSize) {
        this.$refs.vxeModal.zoom()
        this.zoomIn = true
      }
    })

    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)
    this.getDicts('iot_task_state').then((response) => {
      this.taskStatusOptions = response.data
    })
    this.handleUpdateMap()
    if (this.videoType === 'rtmp') {
      this.getFlv('init')
    } else {
      this.initRtcCamera()
    }

    if (this.videoType === 'brtc') {
      // 百度RTC初始化处理 'apppe7fxez6rjdn', 'f6ieszre7fxp', '1', '10'
      this.start()
    }
  },
  beforeDestroy() {
    // 重置遥控ack数据
    console.log('beforeDestroy *****')
    this.$store.dispatch('resetAck')
    // 退出定期执行控制
    clearInterval(this.timerRun)
    //  登出车辆遥控
    this.logoutVehicle()
    // this.$store.dispatch('stopWebSocket')
  },
  destroyed() {
    // 移除监控键盘时间
    window.removeEventListener('keyup', this.handleKeyup)
    window.removeEventListener('keydown', this.handleKeydown)
  },
  watch: {
    showLeftModal(newVal, oldVal) {
      const isMaxSize = this.$refs.vxeModal.isMaximized()
      if (!isMaxSize && !newVal) {
        this.$refs.vxeModal.zoom()
      }
    },
    'info.vehicleId'(newVal, oldVal) {
      console.log('info')
      if (newVal !== oldVal) {
        this.handleCloaseCtrl()
        this.handleUpdateMap()
      }
    },
    'info.inc'(newVal, oldVal) {
      console.log('info')
      console.log('mapUpdateObjectPoints lng')
      this.handleUpdateMap()
    },
    ctrlVisible(newVal, oldVal) {
      console.log('ctrl ctrlVisible oldVal:', oldVal)
      console.log('ctrl ctrlVisible newVal:', newVal)
    },
    ackData(newVal, oldVal) {
      this.handleAckData(newVal)
    },
    vehicleLoginInfo(newVal, oldVal) {
      this.handleVehicleLoginInfo(newVal)
    },
    'info.location'(newVal, oldVal) {
      this.setVehicleAutoMode()
    }
  },
  methods: {
    selectDevice(device) {
      this.activeDevice = device
      // Reset all device switches
      // this.cmd.fogcannon_switch = false
      // this.cmd.sprayrod_switch = false
      // this.cmd.nozzle_switch = false
      // // Set the selected device switch to true
      // switch (device) {
      //   case 'fogCannon':
      //     this.cmd.fogcannon_switch = true
      //     break
      //   case 'sprayArm':
      //     this.cmd.sprayrod_switch = true
      //     break
      //   case 'nozzle':
      //     this.cmd.nozzle_switch = true
      //     break
      // }
      // this.sendVehicleCMD()
    },

    switchChange(checked) {
      this.switchConfig[this.activeDevice] = checked
      switch (this.activeDevice) {
        case 'fogCannon':
          this.cmd.fogcannon_switch = checked
          break
        case 'sprayArm':
          this.cmd.sprayrod_switch = checked
          break
        case 'nozzle':
          this.cmd.nozzle_switch = checked
          break
      }
      this.sendVehicleCMD()
    },

    // Device control methods
    turnLeftClickDownofDevice() {
      if (!this.isCanSendWebsocketCmd) return
      const prefix = this.getDevicePrefix()
      if (prefix) {
        this.cmd[`${prefix}_left`] = true
        this.sendVehicleCMD()
      }
    },

    turnLeftClickUpofDevice() {
      if (!this.isCanSendWebsocketCmd) return
      const prefix = this.getDevicePrefix()
      if (prefix) {
        this.cmd[`${prefix}_left`] = false
        this.sendVehicleCMD()
      }
    },

    turnRightClickDownofDevice() {
      if (!this.isCanSendWebsocketCmd) return
      const prefix = this.getDevicePrefix()
      if (prefix) {
        this.cmd[`${prefix}_right`] = true
        this.sendVehicleCMD()
      }
    },

    turnRightClickUpofDevice() {
      if (!this.isCanSendWebsocketCmd) return
      const prefix = this.getDevicePrefix()
      if (prefix) {
        this.cmd[`${prefix}_right`] = false
        this.sendVehicleCMD()
      }
    },

    forwardofDevice() {
      if (!this.isCanSendWebsocketCmd) return
      const prefix = this.getDevicePrefix()
      if (prefix) {
        this.cmd[`${prefix}_up`] = true
        this.sendVehicleCMD()
      }
    },

    reversingofDevice() {
      if (!this.isCanSendWebsocketCmd) return
      const prefix = this.getDevicePrefix()
      if (prefix) {
        this.cmd[`${prefix}_down`] = true
        this.sendVehicleCMD()
      }
    },

    getDevicePrefix() {
      switch (this.activeDevice) {
        case 'fogCannon':
          return 'fogcannon'
        case 'sprayArm':
          return 'sprayrod'
        case 'nozzle':
          return 'nozzle'
        default:
          return null
      }
    },

    controlDevice(direction) {
      // TODO: Implement device control logic based on activeDevice and direction
      console.log(`Controlling ${this.activeDevice} in direction: ${direction}`)
    },

    initLogin() {
      const { timestamp, seq } = getTimestampAndSeq(this.vehicleId)
        AddStartRecord('login', timestamp, seq)
        return new Promise((resolve, reject) => {
          vehicleLogin({
          vehicleId: this.vehicleId,
          seq: seq,
          timestamp: timestamp
        })
          .then((res) => {
            console.log('getVehicleLoginStatus ** screen', res)
            if (res.data.result) {
              // 遥控登录seq用于登录ack校验
              this.vehicleLoginInfo = {
                loginSeq: res.data.seq,
                loginTimestamp: res.data.timestamp
              }
            } else {
              this.$warningEx('登录车辆失败，或者该车辆正在被其他人遥控中，请稍后再试！')
            }
          resolve(res.data.result)
          })
          .catch((err) => {
            reject(err)
          })
        })
    },
    toRtcScreen() {
      this.$emit('targeCtrlType', '2')
    },
    changeAdminVideoType() {
      if (!this.isAdmin) {
        this.isAdmin = true
      }
      if (this.signAdminVideoType === 'rtc') {
        this.$confirm({
          title: '警告！！！',
          content: '你正在请求使用RTMP模式的视频，该视频会带来更高的延迟，并造成遥控时的巨大危险',
          okText: '确认使用RTMP',
          cancelText: '继续使用RTC',
          zIndex: 1000000,
          onOk: () => {
            this.setAdminVideoType()
          },
          onCancel() {}
        })
      } else {
        this.setAdminVideoType()
      }
    },
    setAdminVideoType() {
      this.signAdminVideoType = this.signAdminVideoType === 'rtc' ? 'rtmp' : 'rtc'
      this.reLoadRevo = false
      this.$nextTick(() => {
        this.reLoadRevo = true
      })
      if (this.signAdminVideoType === 'rtmp') {
        this.getFlv(this.firtstInit ? '' : 'init')
        this.firtstInit = true
      } else {
        this.initRtcCamera()
      }
    },
    setVehicleAutoMode() {
      const tmpLocation = this.info ? this.info.location : null
      // 初始化时设置
      if (tmpLocation) {
        // 当前驾驶模式状态只能取自于实时状态数据
        this.automodeFlag = tmpLocation.controlMode === 1 || tmpLocation.controlMode === 2
      }
    },
    handleVehicleLoginInfo(loginInfo) {
      this.isLoginSuccess = false
      if (loginInfo) {
        this.loginSeq = loginInfo.loginSeq
        this.loginTimestamp = loginInfo.loginTimestamp
      }
    },
    handleAckData(newVal) {
      console.log('遥控收到数据', JSON.stringify(newVal))
      if (newVal && newVal.login) {
        const loginResult = newVal.login.result
        const getLoginSeq = newVal.login.seq
        if (loginResult === 0 || loginResult === 2) {
          // 比较登录seq是否一致
          if (getLoginSeq === this.loginSeq) {
            console.log(`遥控登录成功 loginSeq=${this.loginSeq} getLoginSeq=${getLoginSeq}`)
            // 取消上一次登录标记
            this.loginSeq = undefined
            this.isLoginSuccess = true
            this.isCmdSuccess = true
            return
          }
        }
        const cmdResult = newVal.cmd ? newVal.cmd.result : 0
        // 命令成功
        this.isCmdSuccess = cmdResult === 0 || cmdResult === 2
        // 判断是否重新登录
        if (loginResult === 1 || cmdResult === 1) {
          this.loginVehicle()
        }
      }

      if (newVal && newVal.automode) {
        const currentAutoModeSeq = newVal.automode.seq
        if (this.automodeSeq === currentAutoModeSeq) {
          this.isSwitchAutoModeSuccess = true
          console.log('遥控模式切换成功')
        }
      }
    },
    // 初始化RTC摄像头
    initRtcCamera() {
      this.$set(this.mainCamera, 'info', this.getRtcDestDeviceInfo(RtcCameraType.FRONT_CAMERA))
      this.$set(this.leftCamera, 'info', this.getRtcDestDeviceInfo(RtcCameraType.LEFT_CAMERA))
      this.$set(this.rightCamera, 'info', this.getRtcDestDeviceInfo(RtcCameraType.RIGHT_CAMERA))
      this.$set(this.backCamera, 'info', this.getRtcDestDeviceInfo(RtcCameraType.BACK_CAMERA))
    },

    postData(url, requestPath, data) {
      const requestUrl = url + requestPath
      // Default options are marked with *
      return fetch(requestUrl, {
        body: JSON.stringify(data), // must match 'Content-Type' header
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, same-origin, *omit
        headers: {
          'content-type': 'application/json',
          // 'Authorization': 'Basic YWRtaW46YWRtaW4='
          Authorization: 'Basic YWRtaW46YWRtaW4jMjAyMDEyMTU='
        },
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer' // *client, no-referrer
      }).then((response) => response.json()) // parses response to JSON
    },

    getImgSrc(imgName, onVal) {
      if (!this.isCanSendWebsocketCmd) {
        return require('@/assets/images/control/' + imgName + '-grey.png')
      } else if (onVal) {
        return require('@/assets/images/control/' + imgName + '-on.png')
      } else {
        return require('@/assets/images/control/' + imgName + '.png')
      }
    },
    handleUpdateMap() {
      if (this.$refs.mapRef) {
        if (this.info) {
          this.$refs.mapRef.mapUpdateObjectPoints([this.info])
        }
      } else {
        setTimeout(() => {
          this.handleUpdateMap()
        }, 1000)
      }
    },
    initTimer() {
      this.timerRun = setInterval(() => {
        this.sendVehicleCMD()
      }, this.timer)
    },
    // 发送遥控指令
    async sendVehicleCMD() {
      if (!this.websocketReady) {
        await this.$store.dispatch('websocketCmdConnect')
      }
      // 登录成功 + 命令成功 +自动模式才能发送命令
      if (this.vehicleId && this.isLoginSuccess && this.isCmdSuccess && this.automodeFlag) {
        console.log('发送遥控命令')
        const { timestamp, seq } = getTimestampAndSeq(this.vehicleId)
        AddStartRecord('cmd', timestamp, seq)
        this.cmd.timestamp = timestamp
        this.cmd.seq = seq
        this.$store
          .dispatch('websocketCmdSend', {
            id: this.vehicleId,
            cmd: this.cmd
          })
          .then(() => {})
          .finally(() => {})
      }
    },

    loginVehicle() {
      console.log('loginVehicle', this.isLoginSuccess)
      if (!this.isLoginSuccess) {
        // 等待登录返回
        // 上一次登录未返回，但又要求发送登录，todo...
        return
      }
      this.timerRun && clearInterval(this.timerRun)
      console.log('遥控重新登录中')
      const { timestamp, seq } = getTimestampAndSeq(this.vehicleId)
      AddStartRecord('login', timestamp, seq)
      this.isLoginSuccess = false
      login({
        vehicleId: this.vehicleId,
        data: {
          timestamp: timestamp,
          seq: seq
        }
      }).then((res) => {
        this.loginSeq = res.data.seq
        this.loginTimestamp = res.data.timestamp
        console.log('新登录key', this.loginSeq)
        // 此处要追加一次处理，避免WS已返回
        this.handleAckData(this.ackData)
        this.initTimer()
      })
    },

    logoutVehicle() {
      console.log('登出车辆遥控')
      const { timestamp, seq } = getTimestampAndSeq(this.vehicleId)
      AddStartRecord('logout', timestamp, seq)
      logout({
        vehicleId: this.vehicleId,
        data: { timestamp: timestamp, seq: seq }
      }).then((response) => {
        console.log('run 登出车辆遥控 end')
        this.timerRun && clearInterval(this.timerRun)
      })
    },
    automode() {
      console.log('自动')
      // 登录成功 + 命令正常 + 非模式切换进行中
      const msg = `isLoginSuccess=${this.isLoginSuccess},isCmdSuccess=${this.isCmdSuccess},isSwitchAutoModeSuccess=${this.isSwitchAutoModeSuccess}`
      console.log(msg)
      if (!this.isLoginSuccess || !this.isCmdSuccess || this.isSwitchAutoModeSuccess === false) {
        return
      }
      // 正在切换模式
      this.isSwitchAutoModeSuccess = false
      this.autoModeAction = true
      const { timestamp, seq } = getTimestampAndSeq(this.vehicleId)
      console.log(`自动模式 automode seq=${seq},timestamp=${timestamp}`)
      AddStartRecord('automode', timestamp, seq)
      setAutomode({
        vehicleId: this.vehicleId,
        data: {
          automode: true,
          timestamp: timestamp,
          seq: seq
        }
      }).then((res) => {
        console.log('run auto mode end')
        this.automodeSeq = res.data.seq
        console.log('automodeSeq', this.automodeSeq)
        this.handleAckData(this.ackData)
      })
    },

    unautomode() {
      console.log('手动')
      const msg = `isLoginSuccess=${this.isLoginSuccess},isCmdSuccess=${this.isCmdSuccess},isSwitchAutoModeSuccess=${this.isSwitchAutoModeSuccess}`
      console.log(msg)
      // 登录成功 + 命令正常 + 非模式切换进行中
      if (!this.isLoginSuccess || !this.isCmdSuccess || this.isSwitchAutoModeSuccess === false) {
        return
      }
      // 正在切换模式
      this.isSwitchAutoModeSuccess = false
      this.autoModeAction = false
      const { timestamp, seq } = getTimestampAndSeq(this.vehicleId)
      console.log(`手动模式 automode seq=${seq},timestamp=${timestamp}`)
      AddStartRecord('automode', timestamp, seq)
      setAutomode({
        vehicleId: this.vehicleId,
        data: {
          automode: false,
          timestamp: timestamp,
          seq: seq
        }
      }).then((res) => {
        console.log('run unauto mode end')
        this.automodeSeq = res.data.seq
        console.log('automodeSeq', this.automodeSeq)
        this.handleAckData(this.ackData)
      })
    },
    // keyup键盘事件
    handleKeyup(event) {
      // eslint-disable-next-line no-caller
      const e = event || window.event || arguments.callee.caller.arguments[0]
      if (!e) return
      const { key, keyCode } = e
      console.log(`key=${key}, keyCode=${keyCode}`)

      if (this.activeTab === 'device') {
        // Device control mode
        if (keyCode === 87 || keyCode === 83) { // W or S key
          const prefix = this.getDevicePrefix()
          if (prefix) {
            this.cmd[`${prefix}_up`] = false
            this.cmd[`${prefix}_down`] = false
          }
        }
        if (keyCode === 65 || keyCode === 68) { // A or D key
          const prefix = this.getDevicePrefix()
          if (prefix) {
            this.cmd[`${prefix}_left`] = false
            this.cmd[`${prefix}_right`] = false
          }
        }
      } else {
        // Vehicle control mode
        if (keyCode === 32 || keyCode === 74 || keyCode === 75) {
          if (keyCode === 74) {
            // 加油
            this.throttleBtn = false
            this.cmd.throttle = false
          }
          if (keyCode === 75) {
            // 刹车
            this.brakeBtn = false
            this.cmd.brake = false
          }
        }
        if (keyCode === 65 || keyCode === 68) {
          if (keyCode === 65) {
            // 左转
            this.leftBtn = false
            this.cmd.left = false
          }
          if (keyCode === 68) {
            // 右转
            this.rightBtn = false
            this.cmd.right = false
          }
        }
      }
      this.sendVehicleCMD()
    },
    // keydown键盘事件
    handleKeydown(event) {
      // eslint-disable-next-line no-caller
      const e = event || window.event || arguments.callee.caller.arguments[0]
      if (!e) return
      const { key, keyCode } = e
      console.log(`key=${key}, keyCode=${keyCode}`)

      if (this.activeTab === 'device') {
        // Device control mode
        if (keyCode === 87 || keyCode === 83) { // W or S key
          const prefix = this.getDevicePrefix()
          if (prefix) {
            if (keyCode === 87) { // W key - up
              this.cmd[`${prefix}_up`] = true
              this.cmd[`${prefix}_down`] = false
            } else if (keyCode === 83) { // S key - down
              this.cmd[`${prefix}_down`] = true
              this.cmd[`${prefix}_up`] = false
            }
          }
        }
        if (keyCode === 65 || keyCode === 68) { // A or D key
          const prefix = this.getDevicePrefix()
          if (prefix) {
            if (keyCode === 65) { // A key - left
              this.cmd[`${prefix}_left`] = true
              this.cmd[`${prefix}_right`] = false
            } else if (keyCode === 68) { // D key - right
              this.cmd[`${prefix}_right`] = true
              this.cmd[`${prefix}_left`] = false
            }
          }
        }
      } else {
        // Vehicle control mode
        if (keyCode === 32 || keyCode === 74 || keyCode === 75) {
          if (keyCode === 32) {
            // 紧急停车
            this.emergencyStop = true
            this.throttleBtn = false
            this.brakeBtn = false

            // 紧急停车把档位跳到停车档
            this.parkingBtn = true
            this.upGearBtn = false
            this.downGearBtn = false
            this.neutralPositionBtn = false

            this.cmd.slambrake = true
            this.cmd.parking = false
            this.cmd.drive = false
            this.cmd.reverse = false
            this.cmd.neutral = false
            this.cmd.brake = false
            this.cmd.throttle = false
          }
          if (keyCode === 74) {
            // 加油
            this.throttleBtn = true
            this.emergencyStop = false
            this.brakeBtn = false

            this.cmd.brake = false
            this.cmd.throttle = true
          }
          if (keyCode === 75) {
            // 刹车
            this.brakeBtn = true
            this.emergencyStop = false
            this.throttleBtn = false

            this.cmd.throttle = false
            this.cmd.brake = true
          }
        }
        if (keyCode === 65 || keyCode === 68) {
          if (keyCode === 65) {
            // 左转
            this.leftBtn = true
            this.rightBtn = false
            this.emergencyStop = false

            this.cmd.left = true
            this.cmd.right = false
          }
          if (keyCode === 68) {
            // 右转
            this.rightBtn = true
            this.leftBtn = false
            this.emergencyStop = false

            this.cmd.right = true
            this.cmd.left = false
          }
        }
        if (keyCode === 87 || keyCode === 83 || keyCode === 78 || keyCode === 80) {
          if (keyCode === 87) {
            // 前进档
            this.upGearBtn = true
            this.downGearBtn = false
            this.neutralPositionBtn = false
            this.parkingBtn = false
            this.emergencyStop = false

            this.cmd.drive = true
            this.cmd.parking = false
            this.cmd.neutral = false
            this.cmd.reverse = false
            this.cmd.slambrake = false
          }
          if (keyCode === 83) {
            // 倒退档
            this.downGearBtn = true
            this.upGearBtn = false
            this.neutralPositionBtn = false
            this.parkingBtn = false
            this.emergencyStop = false

            this.cmd.drive = false
            this.cmd.parking = false
            this.cmd.neutral = false
            this.cmd.reverse = true
            this.cmd.slambrake = false
          }
          if (keyCode === 78) {
            // 空档
            this.neutralPositionBtn = true
            this.upGearBtn = false
            this.downGearBtn = false
            this.parkingBtn = false
            this.emergencyStop = false

            this.cmd.drive = false
            this.cmd.parking = false
            this.cmd.neutral = true
            this.cmd.reverse = false
            this.cmd.slambrake = false
          }
          if (keyCode === 80) {
            // 停车
            this.parkingBtn = true
            this.upGearBtn = false
            this.downGearBtn = false
            this.neutralPositionBtn = false
            this.emergencyStop = false

            this.cmd.drive = false
            this.cmd.parking = true
            this.cmd.neutral = false
            this.cmd.reverse = false
            this.cmd.slambrake = false
          }
        }
      }
      this.sendVehicleCMD()
    },
    turnLeftClickDown() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.leftBtn = true
      this.rightBtn = false
      this.emergencyStop = false

      this.cmd.left = true
      this.cmd.right = false
    },
    turnLeftClickUp() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.leftBtn = false
      // 退出增加向左
      this.cmd.left = false
    },
    turnRightClickDown() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.rightBtn = true
      this.leftBtn = false
      this.emergencyStop = false

      this.cmd.right = true
      this.cmd.left = false
    },
    turnRightClickUp() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.rightBtn = false
      // 退出增加向右
      this.cmd.right = false
    },
    neutral() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      // 空档
      this.neutralPositionBtn = true
      this.upGearBtn = false
      this.downGearBtn = false
      this.parkingBtn = false
      this.emergencyStop = false

      this.cmd.drive = false
      this.cmd.parking = false
      this.cmd.neutral = true
      this.cmd.reverse = false
      this.cmd.slambrake = false
    },
    parking() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      // 停车
      this.parkingBtn = true
      this.upGearBtn = false
      this.downGearBtn = false
      this.neutralPositionBtn = false
      this.emergencyStop = false

      this.cmd.drive = false
      this.cmd.parking = true
      this.cmd.neutral = false
      this.cmd.reverse = false
      this.cmd.slambrake = false
    },
    reversing() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      // 倒车
      this.downGearBtn = true
      this.upGearBtn = false
      this.neutralPositionBtn = false
      this.parkingBtn = false
      this.emergencyStop = false

      this.cmd.drive = false
      this.cmd.parking = false
      this.cmd.neutral = false
      this.cmd.reverse = true
      this.cmd.slambrake = false
    },
    forward() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      // 前进档
      this.upGearBtn = true
      this.downGearBtn = false
      this.neutralPositionBtn = false
      this.parkingBtn = false
      this.emergencyStop = false

      this.cmd.drive = true
      this.cmd.parking = false
      this.cmd.neutral = false
      this.cmd.reverse = false
      this.cmd.slambrake = false
    },
    estop() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.emergencyStop = true
      this.throttleBtn = false
      this.brakeBtn = false

      // 紧急停车把档位跳到停车档
      this.parkingBtn = true
      this.upGearBtn = false
      this.downGearBtn = false
      this.neutralPositionBtn = false

      this.cmd.slambrake = true
      this.cmd.parking = true
      this.cmd.drive = false
      this.cmd.reverse = false
      this.cmd.neutral = false
      this.cmd.brake = false
      this.cmd.throttle = false
    },
    guanDown() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.throttleBtn = true
      this.emergencyStop = false
      this.brakeBtn = false

      this.cmd.brake = false
      this.cmd.throttle = true
    },
    guanUp() {
      this.cmd.throttle = false
    },
    brakeDown() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.brakeBtn = true
      this.emergencyStop = false
      this.throttleBtn = false

      this.cmd.throttle = false
      this.cmd.brake = true
    },
    brakeUp() {
      if (!this.isCanSendWebsocketCmd) {
        return
      }
      this.brakeBtn = false
      this.cmd.brake = false
    },
    handleCloaseCtrl() {
      console.log('handleCloaseCtrl ** srceen')
      this.ctrlVisible = false
      // 移除监控键盘时间
      window.removeEventListener('keyup', this.handleKeyup)
      window.removeEventListener('keydown', this.handleKeydown)
      this.$emit('closeModalCtrl')
    },
    handleReady(e) {
      this.$emit('handleReady', e)
    },
    handleError(e) {
      this.$emit('handleError', e)
    },
    getVehicleCameraDeviceInfo(info) {
      console.log('getVehicleCameraDeviceInfo ****', this.rtcInfo)
      if (this.rtcInfo && this.rtcInfo.vehicleCameraDeviceInfos) {
        for (const item of this.rtcInfo.vehicleCameraDeviceInfos) {
          if (item.cameraType) {
            if (info.cameraAddr.indexOf(item.cameraType) > -1) {
              return item
            }
          } else {
            if (info.cameraName === item.cameraName) {
              return item
            }
          }
        }
      }
      return {}
    },
    // 获取对端摄像头
    getRtcDestDeviceInfo(cameraType) {
      if (this.rtcInfo && this.rtcInfo.vehicleCameraDeviceInfos) {
        const deviceList = this.rtcInfo.vehicleCameraDeviceInfos
        for (let n = deviceList.length, k = 0; k < n; k++) {
          if (deviceList[k].cameraType === cameraType) {
            console.log('destCamera', deviceList[k])
            return deviceList[k]
          }
        }
      } else {
        return {}
      }
    },
    async getFlv(type) {
      let cameras
      if (this.info && this.info.cameras && this.info.cameras.length === 0) {
        cameras = await this.rtmpRealTimeVideo()
        if (!cameras || cameras.length.length === 0) {
          return
        }
      }
      // RTMP协议
      postData(this.url, api.ffmpegStart, cameras && cameras.length ? cameras : this.info.cameras)
        .then((data) => {
          console.log('视频流地址result:', data)
          if (type === 'init') {
            this.cameras = data.map((d, idx) => {
              d['cameraVisible'] = false
              // 第一个是主摄像头：前鱼
              // 遥控UI只使用前面4个摄像头
              if (idx === 0) {
                console.log('mainCamera:', d)
                this.mainCamera.url = d['cameraAddr']
                console.log(`mainCamera.url = ${this.mainCamera.url}`)
                this.mainCamera.direction = d.id
                this.mainCamera.info = this.getVehicleCameraDeviceInfo(d)
              } else if (idx === 1) {
                this.leftCamera.url = d['cameraAddr']
                this.leftCamera.direction = d.id
                this.leftCamera.info = this.getVehicleCameraDeviceInfo(d)
              } else if (idx === 2) {
                // 右摄像头
                this.rightCamera.url = d['cameraAddr']
                this.rightCamera.direction = d.id
                this.rightCamera.info = this.getVehicleCameraDeviceInfo(d)
              } else if (idx === 3) {
                // 后摄像头
                this.backCamera.url = d['cameraAddr']
                this.backCamera.direction = d.id
                this.backCamera.info = this.getVehicleCameraDeviceInfo(d)
              }
              return d
            })
          } else {
            for (const item of this.cameras) {
              if (item.id === type) {
                for (const dItem of data) {
                  if (item.id === dItem.id) {
                    if (this.mainCamera.direction === type) {
                      this.mainCamera.url = dItem.cameraAddr
                      this.mainCamera.direction = dItem.id
                      this.mainCamera.info = this.getVehicleCameraDeviceInfo(dItem)
                    } else if (this.leftCamera.direction === type) {
                      this.leftCamera.url = dItem['cameraAddr']
                      this.leftCamera.direction = dItem.id
                      this.leftCamera.info = this.getVehicleCameraDeviceInfo(dItem)
                    } else if (this.rightCamera.direction === type) {
                      this.rightCamera.url = dItem['cameraAddr']
                      this.rightCamera.direction = dItem.id
                      this.rightCamera.info = this.getVehicleCameraDeviceInfo(dItem)
                    } else if (this.backCamera.direction === type) {
                      this.backCamera.url = dItem['cameraAddr']
                      this.backCamera.direction = dItem.id
                      this.backCamera.info = this.getVehicleCameraDeviceInfo(dItem)
                    }

                    item.cameraAddr = dItem.cameraAddr
                    break
                  }
                }
              }
            }
          }
          if (this.videoType === 'rtmp') {
            // this.showAllCamera()
          }
        }) // JSON from `response.json()` call
        .catch((error) => console.error(error))
      function postData(url, requestPath, data) {
        const requestUrl = url + requestPath
        // Default options are marked with *
        return fetch(requestUrl, {
          body: JSON.stringify(data), // must match 'Content-Type' header
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, same-origin, *omit
          headers: {
            'content-type': 'application/json',
            // 'Authorization': 'Basic YWRtaW46YWRtaW4='
            Authorization: 'Basic YWRtaW46YWRtaW4jMjAyMDEyMTU='
          },
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer' // *client, no-referrer
        }).then((response) => response.json()) // parses response to JSON
      }
    },
    rtmpRealTimeVideo() {
      return new Promise((resolve) => {
        rtmpRealTimeVideo({ vehicleId: this.info.vehicleId }).then((res) => {
          if (res.data.length) {
            this.isTianzun = true
            this.cameraVisible = true
          } else {
            this.isTianzun = false
          }
          resolve(res.data)
        })
      })
    },
    vxeClick() {
      console.log('vxeClick')
      this.$refs.vxeModal.close()
    },
    connectVoice(type) {
      if (type === 'on') {
        this.voiceOn = true
        this.openVoice()
      } else {
        this.voiceOn = false
        this.closeVoice()
      }
    },
    vxeZoom() {
      const isMaxSize = this.$refs.vxeModal.isMaximized()
      this.$refs.vxeModal.zoom()
      this.zoomIn = !isMaxSize
    },
    handleOpenChart() {
      this.$refs.chartRef.showModal(this.vehicleId)
    }
  }
}
</script>

<style lang="less" scoped>
.control-header {
  background-color: #0e1116;
  margin-top: 10px;
}

.control-tabs {
  display: flex;
  margin-bottom: 0;
  padding: 0 20px;
}

.tab-item {
  padding: 12px 20px;
  text-align: center;
  color: #666;
  cursor: pointer;
  position: relative;
  margin-right: 20px;

  &.active {
    color: #00e4ff;

    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #00e4ff;
    }
  }

  &:hover:not(.active) {
    color: rgba(0, 228, 255, 0.8);
  }
}

.control-header:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, transparent, rgba(0, 228, 255, 0.3), transparent);
  margin-top: 2px;
}

.device-control {
  flex: 1;
  padding: 15px;

  /deep/ .ant-switch {
  background-color: #666;
  }

  /deep/ .ant-switch-checked {
    background-color: #013a07;
}
}

.device-buttons {
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 32px;
  background: #1c2128;
  padding: 0;
  margin-bottom: 10px;
  border: 1px solid #00e4ff;
}

.device-btn {
  padding: 8px 24px;
  background: transparent;
  border: none;
  color: #898888;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 60%;
    background: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background: #00e4ff;
    color: #000;
  }

  &:hover:not(.active) {
    background: rgba(0, 228, 255, 0.1);
  }
}

.device-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 10px;

  .row-1 {
    display: flex;
    justify-content: space-between;
    .col-1 {
      display: flex;
      justify-content: space-between;
    }
  }
}

.vertical-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.control-btn {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #00e4ff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: rgba(0, 228, 255, 0.2);
  }

  &:active {
    background: #00e4ff;
    color: #000;
  }
}

.ctrl-container {
  margin: auto;
  width: 100%;
  height: 100% !important;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  //border: 1px solid red;
  display: flex;
  .ctrl-left,
  .ctrl-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    //min-width: 310px;
    .side-camera {
      min-height: 180px;
      height: 30%;
    }
  }
  .ctrl-center {
    flex: 2;
    //min-width: 660px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    .main-camera {
      min-height: 370px;
      height: calc(50% + 10px);
    }
    .ctrl-btn {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-color: #0e1116;
      .btn-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 500px;
        .row-1 {
          display: flex;
          justify-content: space-between;
          .col-1 {
            display: flex;
            justify-content: space-between;
          }
          .col-2 {
            display: flex;
            justify-content: space-between;
            .col-2-right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 15px;
              .ctrl-mode {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .ctrl-mode-text {
                  color: #067b7a;
                }
                .ctrl-mode-text-grey {
                  color: #3a3a3a;
                }
              }
              .ctrl-jk {
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
        .ctrl-estop {
          margin-top: 20px;
        }
      }
      .btn-v {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 15px;
      }
      .ctrl-btn-right {
        margin-left: 15px;
      }
    }
  }
  .side-camera {
    margin-bottom: 10px;
  }
  .ctrl-right {
    .ctrl-map {
      flex: 1;
      border-radius: 10px;
      // margin-top: 10px;
      background-color: #0e1116;
    }
  }
  .ctrl-left {
    .ctrl-info {
      display: flex;
      padding-top: 10px;
      height: calc(70% - 10px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #0e1116;
      border-radius: 10px;
      padding-bottom: 10px;
      .info-item {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
      .speed {
        position: relative;
        margin-top: 25px;
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .speed-over {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .speed-text {
            font-size: 30px;
            font-weight: 700;
            color: #fff;
          }
          .speed-label {
            font-size: 14px;
            font-weight: 700;
            color: #fff;
          }
        }
      }
      .rotate {
        position: relative;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 100%;
        .rotate-arrow {
          position: absolute;
          transform-origin: 50% 50%;
          z-index: 0;
        }
        .rotate-over {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .rotate-text {
            font-size: 20px;
            font-weight: 700;
            color: #fff;
            z-index: 1;
          }
          .rotate-label {
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            z-index: 1;
          }
        }
      }
      .info-content {
        margin-top: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .soc {
        color: #fff;
        margin-top: 20px;
        background: url('../../../../../../assets/images/control/bg-power.png') no-repeat;
        height: 40px;
        background-position: bottom;
        display: flex;
        flex-direction: column;
      }
      .brake,
      .state {
        color: #fff;
        margin-top: 20px;
        background: url('../../../../../../assets/images/control/bg-info.png') no-repeat;
        height: 30px;
        background-position: bottom;
        background-size: 100% auto;
      }
      .state {
        margin-bottom: 20px;
      }
    }
  }
}

.ctrl-bg /deep/ .vxe-modal--box {
  background: url('../../../../../../assets/images/control/bg.jpg');
  background-size: 100% 100%;
}
.no-video-small {
  background: #191f25 url('../../../../../../assets/images/control/no-video-small.png') center center no-repeat;
  background-size: 100% auto;
}
.no-video-big {
  background: #191f25 url('../../../../../../assets/images/control/no-video-big.png') center center no-repeat;
  background-size: 100% auto;
}
div,
span {
  caret-color: transparent;
}

.ctrl-container /deep/ .map-page {
  > div {
    border-radius: 10px;
  }
}

.ctrl-container /deep/ .BMap_cpyCtrl.BMap_noprint.anchorBL {
  display: none;
}
.remote-control-warn /deep/ .vxe-modal--header-title {
  color: red !important;
}
.b-rtc {
  display: flex;
  justify-content: center;
  align-items: center;
}
#therevideo,#therevideo2,#therevideo3,#therevideo4 {
  flex: 1;
}

@media (max-width: 4096px) {
  .btn-list {
    width: 40%;
    transform: scale(1.2, 1.2);
  }
  .speed-media {
    font-size: 30px !important;
  }
  .speed-label-media {
    font-size: 20px !important;
  }

  .rotate-media {
    font-size: 24px !important;
  }
  .rotate-label-media {
    font-size: 20px !important;
  }
}
@media (max-width: 2560px) {
  .btn-list {
    width: 55%;
    transform: scale(1.2, 1.2);
  }
  .speed-media {
    font-size: 26px !important;
  }
  .speed-label-media {
    font-size: 16px !important;
  }

  .rotate-media {
    font-size: 20px !important;
  }
  .rotate-label-media {
    font-size: 16px !important;
  }
}
@media (max-width: 1920px) {
  .btn-list {
    width: 60%;
    transform: scale(1, 1);
  }
  .speed-media {
    font-size: 22px !important;
  }
  .speed-label-media {
    font-size: 12px !important;
  }

  .rotate-media {
    font-size: 18px !important;
  }
  .rotate-label-media {
    font-size: 12px !important;
  }
}
</style>
